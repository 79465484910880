import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { getMenuByProfile, menuLabels } from "./menuItems";
import {
  Toolbar,
  Hidden,
  IconButton,
  Box,
  Grid,
  Collapse,
  Link,
} from "@material-ui/core";
import { Menu as MenuIcon, Cancel as CancelIcon } from "@material-ui/icons";

import tokenProvider from "../../core/tokenProvider";
import Today from "./Today";
import MenuTouch from "./MenuTouch";
import Logo from "./Logo";
import OfficeSelect from "./OfficeSelect";
import LogOutUser from "helpers/LogOutUser";
import LogoutLink from "./LogoutLink";
import ConnectedUser from "./ConnectedUser";
import Config from "config";
import {
  AppBarWrapper,
  AppBar,
  NavbarGrid,
  MainNavButton,
  SettingsNav,
  StatusBar,
  LinkButton,
  LinkButtonText,
} from "./styled";
import useAppState from "../../helpers/useAppState";

const VersionNumber = () => {
  const version = Config.get("version");
  return (
    <p style={{ marginLeft: "auto", paddingRight: "16px", color: "#20274a3b" }}>
      v{version}
    </p>
  );
};

const Navbar = ({ location, logoLink, logoAlt = "" }) => {
  const { pathname } = location;

  const [isOpenMenuTouch, setIsOpenMenuTouch] = React.useState(false);
  const [isOpenSettingsNav, setIsOpenSettingsNav] = React.useState(
    pathname.includes("/settings/")
  );

  const userProfile = useAppState().user.profile;
  const menuItems = getMenuByProfile(userProfile);
  const hasToken = tokenProvider.getToken();

  const closeSettingsNav = () => {
    setIsOpenSettingsNav(false);
  };

  const isActiveMainNavButton = (route) =>
    pathname === route && !isOpenSettingsNav;

  const handleSettingsOnclick = (href) =>
    href === "/settings" && setIsOpenSettingsNav(true);
  return (
    hasToken && (
      <AppBarWrapper>
        <AppBar position="fixed">
          <Box px={2}>
            <Toolbar disableGutters>
              <NavbarGrid container>
                <Hidden mdDown>
                  <Grid item lg={4} className="main-nav">
                    {menuItems.mainNav.map(
                      ({ title, href, icon, isNavLink }) => {
                        const mainNavButton = (
                          <MainNavButton
                            key={title}
                            title={title.toUpperCase()}
                            className={classnames({
                              active: isNavLink
                                ? isActiveMainNavButton(href)
                                : isOpenSettingsNav,
                            })}
                            onClick={() => handleSettingsOnclick(href)}
                          >
                            {icon}
                          </MainNavButton>
                        );

                        return isNavLink ? (
                          <NavLink
                            to={href}
                            key={`${title}-link`}
                            onClick={closeSettingsNav}
                          >
                            {mainNavButton}
                          </NavLink>
                        ) : (
                          mainNavButton
                        );
                      }
                    )}
                  </Grid>
                </Hidden>

                <Grid item xs={10} lg={4} className="logo">
                  <Logo src={logoLink} alt={logoAlt} />
                </Grid>

                <Hidden mdDown>
                  <Grid item lg={4} className="info">
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item>
                        <ConnectedUser />
                        &nbsp;&nbsp;&nbsp;
                      </Grid>
                      <Grid item>
                        <Today className="info__date" />
                      </Grid>
                    </Grid>
                    <OfficeSelect htmlId="navbar-office-selector" />
                  </Grid>
                </Hidden>

                <Hidden lgUp>
                  <Grid item xs={2} className="menu-icon">
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="end"
                      onClick={() => {
                        setIsOpenMenuTouch(true);
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                </Hidden>
              </NavbarGrid>
            </Toolbar>
          </Box>
          <Hidden mdDown>
            <Collapse in={isOpenSettingsNav}>
              <SettingsNav>
                {menuItems.settingsLinks.map(({ text, href }) => (
                  <LinkButton key={text} to={href}>
                    {text.toUpperCase()}
                  </LinkButton>
                ))}
                <VersionNumber />
              </SettingsNav>
            </Collapse>
          </Hidden>
          <Hidden mdDown>
            <StatusBar>
              <Link
                className="banner-announcement"
                href="https://view.monday.com/4671272879-8a99690c6efbcd24cf904d7e235ae25b?r=use1"
                target="_blank"
              >
                <span role="img">📣 &nbsp;</span> Cliquez ici pour découvrir le
                portail client !
              </Link>
              {menuItems.status.map(({ text, href, icon }) => (
                <LinkButton key={text} to={href}>
                  {icon}
                  <LinkButtonText>{text.toUpperCase()}</LinkButtonText>
                </LinkButton>
              ))}

              <LogOutUser>
                {(logout) => (
                  <LogoutLink button logout={logout} loginPage={"/login"}>
                    <CancelIcon />
                    <LinkButtonText>
                      {menuLabels.logout.toUpperCase()}
                    </LinkButtonText>
                  </LogoutLink>
                )}
              </LogOutUser>
            </StatusBar>
          </Hidden>
        </AppBar>
        <MenuTouch
          menuItems={menuItems}
          open={isOpenMenuTouch}
          handleDrawerClose={() => {
            setIsOpenMenuTouch(false);
          }}
        />
      </AppBarWrapper>
    )
  );
};

Navbar.propTypes = {
  location: PropTypes.object.isRequired,
  logoLink: PropTypes.string.isRequired,
  logoAlt: PropTypes.string,
};

export default withRouter(Navbar);
